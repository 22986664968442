/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import React, { Fragment, useCallback } from 'react';
import Sticky from 'react-stickynode';
import { useStickyState } from '../contexts/app/app.provider';
import Header from './header/header';
import Footer from './footer/footer';
import { Waypoint } from 'react-waypoint';
import { useStickyDispatch } from '../contexts/app/app.provider';
import { Script } from 'gatsby';

export default function Layout({ children }) {
  const isSticky = useStickyState('isSticky');
  const dispatch = useStickyDispatch();
  const setSticky = useCallback(() => dispatch({ type: 'SET_STICKY' }), [dispatch]);
  const removeSticky = useCallback(() => dispatch({ type: 'REMOVE_STICKY' }), [dispatch]);

  const onWaypointPositionChange = ({ currentPosition }) => {
    if (currentPosition === 'above') {
      setSticky();
    }
    if (currentPosition === 'below') {
      removeSticky();
    }
  };

  return (
    <Fragment>
      <Script src={`https://www.googletagmanager.com/gtag/js?id=UA-234762521-1`} />
      <Script id="gtag-config">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-234762521-1');
      `}
      </Script>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          width: '100%',
        }}
      >
        <Sticky enabled={isSticky} innerZ={1000}>
          <Header className={`${isSticky ? 'sticky' : 'unSticky'}`} />
        </Sticky>
        <Waypoint onEnter={removeSticky} onPositionChange={onWaypointPositionChange} />
        <main
          sx={{
            width: '100%',
            flex: '1 1 auto',
          }}
        >
          {children}
        </main>
        <Footer />
      </Box>
    </Fragment>
  );
}
