/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Helmet from 'react-helmet';


function SEO({ description, lang, meta, title }) {


  const metaDescription = description ;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${title}`}
      meta={[
        {
          name: `description`,
          content: 'Adaptly',
        },
        {
          property: `og:title`,
          content: 'Adaptly',
        },
        {
          property: `og:description`,
          content: 'Adaptly',
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: 'Adaptly',
        },
        {
          name: `twitter:title`,
          content: 'Adaptly',
        },
        {
          name: `twitter:description`,
          content: 'Adaptly',
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

export default SEO;
