import React from 'react';
import { Box, Container, Grid, Button, Input, Heading, Text, Label } from 'theme-ui';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { Spinner } from 'theme-ui';
import { StaticImage } from 'gatsby-plugin-image';

const Banner = () => {
  const [email, setEmail] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSubscribed, setIsSubscribed] = React.useState<boolean>();

  const isValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);

  const _handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubscribed(undefined);
    setIsSubmitting(true);
    const result = await addToMailchimp(email);

    if (result.result === 'success') {
      setIsSubscribed(true);
    } else {
      setIsSubscribed(false);
    }

    setIsSubmitting(false);
  };
  return (
    <Box sx={styles.banner} id="banner">
      <Container sx={styles.container}>
        <Grid sx={styles.grid}>
          <Box sx={styles.content}>
            <Heading as="h3">Evidence based training app</Heading>
            <Text as="p">
              We have build the first evidence based training app that tracks your sleep, nutrition,
              steps, water intake and training progress. It utilizes autoregulation with linear
              progression, so it can provides you with the best possible training.
            </Text>
            <Box as="form" sx={styles.form}>
              <Label htmlFor="subscribe" variant="styles.srOnly">
                subscribe
              </Label>
              <Input
                name="subscribe"
                id="subscribe"
                placeholder="Subscribe for updates"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={styles.form.input}
              />
              <Button
                sx={styles.form.button}
                variant="secondary"
                disabled={!isValid || isSubscribed || isSubmitting}
                onClick={_handleSubmit}
              >
                {isSubmitting ? (
                  <Spinner
                    size={24}
                    sx={{
                      color: 'white',
                    }}
                  />
                ) : isSubscribed ? (
                  'Subscribed'
                ) : (
                  'Subscribe'
                )}
              </Button>
            </Box>
          </Box>
          <Box sx={styles.image}>
            <StaticImage
              width={230}
              height={500}
              src={'../assets/banner/screenshot-1.png'}
              alt=""
            />
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default Banner;

const styles: Record<any, any> = {
  banner: {
    pt: ['110px', null, null, null, '150px', '200px'],
    pb: ['50px', null, null, null, '60px', null, '0'],
    backgroundColor: '#F6F8FB',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  container: {
    width: [null, null, null, null, null, null, '1390px'],
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: ['1fr', null, null, '1fr 1fr'],
    gridGap: '0',
  },
  content: {
    margin: 'auto',
    mb: [128, 128, 128, 'auto'],
    mr: 32,
    h3: {
      color: 'black',
      fontWeight: 'bold',
      lineHeight: [1.2],
      fontFamily: 'Comfortaa',
      letterSpacing: ['-.7px', '-1.5px'],
      mb: ['15px', null, null, null, '20px'],
      width: ['100%'],
      maxWidth: ['100%', null, null, '90%', '100%', '600px'],
      fontSize: [6, null, null, '36px', null, '46px'],
    },
    p: {
      fontSize: [1, null, null, 2, null, 3],
      lineHeight: ['26px', null, null, null, 2],
      color: 'text_secondary',
      mb: ['16px', null, null, null, '32px'],
      width: ['100%'],
      maxWidth: ['100%'],
      br: {
        display: ['none', null, null, null, 'inherit'],
      },
    },
  },
  form: {
    display: ['flex'],
    input: {
      borderRadius: ['4px'],
      backgroundColor: '#fff',
      width: ['200px', '240px', null, null, '315px', null, '375px'],
      height: ['45px', null, null, '55px', null, null, '65px'],
      padding: ['0 15px', null, null, '0 25px'],
      fontSize: [1, null, null, 2],
      border: 'none',
      outline: 'none',
      boxShadow:
        'box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
    },
    button: {
      fontSize: [1, null, null, null, 2, '20px'],
      borderRadius: ['4px'],
      padding: ['0 15px'],
      ml: ['10px'],
      backgroundColor: 'secondary',
      width: ['auto', null, null, null, '180px'],
    },
  },
  image: {
    borderRadius: '25px',
    position: 'relative',
    transform: ['translateX(-50%)'],
    left: ['50%', null, null, '75%', '75%', '75%', '100%'],
    width: '220px',
    overflow: 'hidden',
    boxShadow: 'rgba(0, 0, 0, 0.4) 0px 30px 90px',
    img: {
      display: 'flex',
      mixBlendMode: 'darken',
      height: '100%',
    },
  },
  partner: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    mb: ['40px'],
    '> div + div': {
      ml: ['10px', null, null, '20px', null, '30px'],
    },
    img: {
      display: 'flex',
    },
    span: {
      fontSize: [1, null, null, null, 2],
      color: '#566272',
      lineHeight: [1],
      opacity: 0.6,
      display: 'block',
      mb: ['20px', null, null, null, '0px'],
      mr: [null, null, null, null, '20px'],
      flex: ['0 0 100%', null, null, null, '0 0 auto'],
    },
  },
};
