/** @jsx jsx */
import { jsx, NavLink as MenuLink, Link as A, LinkProps } from 'theme-ui';
import { Link as GatsbyLink } from 'gatsby';

export function NavLink({
  path,
  label,
  children,
  ...rest
}: {
  path: string;
  label?: string;
  children?: React.ReactNode;
  rest?: Record<any, any>;
}) {
  return (
    <MenuLink as={GatsbyLink} to={path} {...rest}>
      {children ? children : label}
    </MenuLink>
  );
}
export function Link({
  path,
  label,
  children,
  ...rest
}: {
  path?: string;
  label?: string;
  children?: React.ReactNode;
  rest?: Record<any, any>;
} & LinkProps) {
  return (
    <A as={GatsbyLink} to={path} {...rest}>
      {children ? children : label}
    </A>
  );
}
