import React from 'react';

import SEO from '../components/seo';
import { StickyProvider } from '../contexts/app/app.provider';
import Layout from '../components/layout';
import Banner from '../sections/banner';

export default function IndexPage() {
  return (
    <StickyProvider>
      <Layout>
        <SEO title="Adaptly" />
        <Banner />
      </Layout>
    </StickyProvider>
  );
}
